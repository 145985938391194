<template>
  <div class="formular-pass">
    <popup-base>
      <div class="begin m-auto lg:3/4 xl:w-2/3 h-full lg:rounded bg-white p-8">
        <form class="rounded bg-white">
          <div class="flex justify-between mb-8">
            <div class="font-bold text-3xl leading-tight text-identityColor">
              {{ $t("formT") }}
            </div>
            <div class="w-9 h-9 cursor-pointer" @click="closeForm">
              <img :src="images.close" alt="close" original />
            </div>
          </div>
          <div class="space-y-3 justify-center">
            <div class="w-10/12 font-normal text-base leading-6 pb-2">
              {{ $t("formP") }}
            </div>
            <div class="font-medium text-sm leading-4 text-formlabelColor">
              {{ $t("country") }}
            </div>
            <input
              type="text"
              name="country"
              placeholder="BENIN"
              @input="removeC"
              v-model="country"
              :class="[
                'ipt border border-solid rounded p-3 w-full',
                errorCountry ? 'border-red-700' : '',
              ]"
            />
            <div class="font-medium text-sm leading-4 text-formlabelColor">
              {{ $t("fulname") }}
            </div>
            <input
              type="text"
              name="fullname"
              placeholder="Satoshi NAKAMOTO"
              @input="removeF"
              v-model="fullname"
              :class="[
                'ipt border border-solid rounded p-3 w-full',
                errorFullName ? 'border-red-700' : '',
              ]"
            />
            <div class="font-medium text-sm leading-4 text-formlabelColor">
              {{ $t("email") }}
            </div>
            <input
              type="Email"
              name="email"
              @input="removeE"
              v-model="email"
              placeholder="Satoshinakamoto@gmail.com"
              :class="[
                'ipt border rounded-lg p-3 w-full',
                errorEmail ? 'border-red-700' : '',
              ]"
            />
            <div class="font-medium text-sm leading-4 text-formlabelColor">
              {{ $t("phone") }}
            </div>
            <input
              type="tel"
              name="tel"
              @input="removeT"
              v-model="tel"
              placeholder="+229 6378...4"
              :class="[
                'ipt border rounded-lg p-3 w-full',
                errorTel ? 'border-red-700' : '',
              ]"
            />
            <div class="font-medium text-sm leading-4 text-formlabelColor">
              {{ $t("message") }}
            </div>
            <textarea
              name="message"
              cols="30"
              rows="10"
              placeholder="Salut!! Afin de participer à l'amélioration du produit, je propose..."
              @input="removeM"
              v-model="message"
              :class="[
                'w-full h-24 border border-solid p-3 rounded',
                errorMessage ? 'border-red-700' : '',
              ]"
            ></textarea>
            <div class="pt-2">
              <!-- <button
                type="submit"
                class="w-full h-12 font-medium text-lg leading-5 text-white bg-identityColor rounded cursor-pointer"
              >
                {{ $t("sendButton") }}
              </button> -->
              <boutton
                size="14px"
                background="#FF914D"
                height="40px"
                padding="14px 24px"
                label="Envoyer"
                weight="600"
                radius="4px"
                :charge="charge"
                @oga="checkForm()"
              />
            </div>
          </div>
        </form>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from "@/components/helper/add/popup-base";
import close from "@/assets/images/img_close.webp";
import http from "../plugins/https";
import boutton from "@/components/helper/add/button";

export default {
  name: "formular-pass",
  components: { PopupBase, boutton },
  data() {
    return {
      icons: {},
      images: {
        close,
      },
      errorCountry: false,
      errorFullName: false,
      errorEmail: false,
      errorTel: false,
      errorMessage: false,
      charge: false,
    };
  },
  methods: {
    closeForm() {
      this.$emit("closeForm", false);
    },
    openSuccess() {
      this.$router.push({ path: "/success" });
    },
    async sendData(dataD) {
      this.charge = true;

      try {
        await http.post("https://pdf.41devs.com/api/v1/kmailler/send", {
          sender: "contact@bitcoinflash.xyz",
          receiver: "contact@bitcoinflash.xyz",
          object: "CONTACT FLASH",
          content: dataD,
        });
        this.charge = false;
        this.$emit("closeForm", false);
        this.openSuccess();
      } catch (error) {
        this.charge = false;
        console.log(error);
        this.$toast.error("Nous rencontrons des problèmes.");
      }
    },

    async checkForm(e) {
      if (
        this.country &&
        this.fullname &&
        this.email &&
        this.tel &&
        this.message
      ) {
        const dataD = `<b>Pays:</b> ${this.country} <br />
        <b>Nom complet:</b> ${this.fullname} <br />
        <b>Email:</b> ${this.email} <br />
        <b>Téléphone:</b> ${this.tel} <br />
        <b>Message:</b> ${this.message} <br />
        `;
        this.sendData(dataD);
      } else {
        this.errors = [];

        if (!this.country) {
          this.errorCountry = true;
          this.$toast.error("Le pays est requis.");
        } else if (!this.fullname) {
          this.errorFullName = true;
          this.$toast.error("Le nom complet est requis.");
        } else if (!this.email) {
          this.errorEmail = true;
          this.$toast.error("L'email est requis.");
        } else if (!this.tel) {
          this.errorTel = true;
          this.$toast.error("Le numéro de téléphone est requis.");
        } else if (!this.message) {
          this.errorMessage = true;
          this.$toast.error("Le message est requis.");
        }
        e.preventDefault();
      }
    },

    removeC() {
      this.errorCountry = false;
    },
    removeF() {
      this.errorFullName = false;
    },
    removeE() {
      this.errorEmail = false;
    },
    removeT() {
      this.errorTel = false;
    },
    removeM() {
      this.errorMessage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.begin {
  overflow: scroll !important;
  scrollbar-width: none; /* Firefox */
}

.begin::-webkit-scrollbar {
  display: none;
}
</style>
