<template>
  <popup-base>
    <div class="flex justify-center">
      <div
        class="begin bg-white rounded-lg my-10 mx-5 py-6 w-11/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12"
        :style="{ minHeight: '300px' }"
      >
        <div class="flex justify-between items-center px-5 mb-5">
          <div class="text-2xl text-identityColor font-serif font-bold">
            Selection
          </div>
          <div
            class="h-9 w-9 flex rounded-md cursor-pointer"
            @click="$emit('close')"
          >
            <img src="@/assets/images/img_close.webp" alt="close" original />
          </div>
        </div>
        <div class="px-8">
          <div class="form-content w-full mb-6">
            <div class="label font-normal text-lg mb-2 text-gray-500">Pays</div>
            <multi-select
              height="46px"
              width="100%"
              background="#fff"
              border="1px solid #CDD1D6"
              :option="optionNationalite"
              :value-t="optionNationalite[0]"
              @info="sendNationalite"
            />
          </div>
          <div class="form-content w-full mb-6">
            <div class="label font-normal text-lg mb-2 text-gray-500">
              Réseau
            </div>
            <multi-select
              height="46px"
              width="100%"
              background="#fff"
              border="1px solid #CDD1D6"
              :option="optionGsmNetwork"
              :value-t="gsmNetwork"
              @info="(val) => (gsmNetwork = val)"
            />
          </div>
        </div>
        <div class="px-6 mt-10">
          <div class="flex justify-end">
            <button
              class="border rounded-md py-3 md:px-8 px-5 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
              @click="$emit('close')"
            >
              Annuler
            </button>
            <button
              @click="continueTransactionModal()"
              class="bg-identityColor hover:bg-yellow-600 hover:text-gray-200 py-3 md:px-8 px-5 text-white font-semibold rounded-md ml-3"
            >
              <span v-if="!charge">Continuer</span>
              <easy-spinner v-if="charge" type="dots" size="20" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </popup-base>
</template>
<script>
import popupBase from "@/components/helper/add/popup-base.vue";
import close from "@/assets/icons/fermer.svg";
import MultiSelect from "@/components/helper/form/multiselect.vue";
// import allCountry from "@/components/helper/form/pays.json";
import allGsm from "@/components/helper/form/gsm-network.json";

export default {
  components: { popupBase, MultiSelect },
  props: {
    status: {
      type: Boolean,
    },
  },
  data() {
    return {
      icons: {
        close,
      },
      optionNationalite: [],
      optionGsmNetwork: [],
      dataFirstForm: null,
      nationality: "",
      gsmNetwork: "",
      isSell: this.status,
    };
  },

  created() {
    for (let item in allGsm) {
      this.optionNationalite.push(
        allGsm[item].map + " " + allGsm[item].country
      );
    }
  },

  methods: {
    sendNationalite(a) {
      this.optionGsmNetwork = [];
      this.nationality = a;

      const gsmObject = allGsm.find(
        (gsm) => gsm.map + " " + gsm.country === this.nationality
      );
      if (this.isSell == false) {
        if (gsmObject) {
          for (let it in gsmObject.network) {
            this.optionGsmNetwork.push(gsmObject.network[it]);
          }
        }
      } else {
        this.gsmNetwork = "";
        if (gsmObject) {
          for (let it in gsmObject.networkSell) {
            this.optionGsmNetwork.push(gsmObject.networkSell[it]);
          }
        }
      }
    },

    continueTransactionModal() {
      this.charge = true;
      const data = {
        nationality: this.nationality,
        gsmNetwork: this.gsmNetwork,
      };
      this.dataFirstForm = data;
      if (Object.values(this.dataFirstForm).every((value) => value !== "")) {
        this.charge = false;
        this.$emit("openTransactionModal", this.dataFirstForm);
      } else {
        this.charge = false;
        this.$toast.error("Veillez selectionner l'un des réseaux du pays.");
      }
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/style/sass/variables";
div {
  font-family: $font-family;
}
</style>
