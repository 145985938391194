<template>
  <!-- Header For Large Screen-->
  <div class="header fixed z-50 w-full">
    <div class="flex justify-between items-center w-full shadow-md py-2">
      <div class="w-36 mx-7 lg:mx-40 cursor-pointer">
        <a href="/" class="flex items-center">
          <img
            class="w-16"
            :src="images.logo"
            alt="logo"
            width="64px"
            height="20px"
            original
          />
          <span class="ml-2 font-extrabold text-3xl text-identityColor"
            >Flash</span
          >
        </a>
      </div>
      <div
        class="lg:flex hidden md:hidden font-semibold text-xl leading-7 text-aColor"
      >
        <div class="hover:text-identityColor">
          <a href="#flashx">FlashX</a>
        </div>
        <div>
          <a
            class="text-blue-600 hover:text-identityColor mx-7"
            href="#flashpayment"
            >FlashPayment</a
          >
        </div>
        <div class="hover:text-identityColor mr-7">
          <a href="#faqs">FAQs</a>
        </div>
        <div class="hover:text-identityColor"><a href="#about">About</a></div>
      </div>
      <div class="flex items-center lg:mr-40 mr-7">
        <a href="/yBdashboard" class="hidden">
          <div class="cursor-pointer p-4 text-white">Dashboard</div>
        </a>
        <div class="flex justify-end">
          <button
            @click="openForm()"
            class="flex items-center font-semibold lg:text-lg text-base leading-5 text-white bg-identityColor hover:text-identityColor hover:bg-carouselColor hover:shadow-sm rounded-lg px-2 lg:px-8 py-4 cursor-pointer"
          >
            <img
              class="w-6 mr-3"
              :src="images.inform"
              alt="flash"
              width="24px"
              height="24px"
              original
            />
            {{ $t("button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*Import Images*/
import logo from "../../assets/images/img_logo.png";
import inform from "@/assets/images/img_contact.png";

export default {
  name: "index",
  metaInfo: {
    title: "bitcoinflash",
    meta: [
      {
        name: "bitcoinflash",
        content:
          "Achat du bitcoin à partir de 100 fr CFA en utilisant le lightning network",
      },
    ],
  },
  data() {
    return {
      icons: {},
      images: {
        logo,
        inform,
      },
    };
  },

  methods: {
    openHome() {
      this.$router.push({ path: "/" });
    },
    openDash() {
      this.$router.push({ path: "/dashboard" });
    },
    openForm() {
      this.$emit("openFormHeader", true);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables.scss";
a {
  color: unset;
}
.header {
  background-color: white;
}
</style>
