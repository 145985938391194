<template>
  <div id="about" class="w-full bg-identityColor">
    <div>
      <div class="py-10 px-7 border-b">
        <div class="flex justify-center">
          <div
            class="lg:w-2/3 font-semibold text-2xl leading-relaxed text-white text-center"
          >
            {{ $t("Together") }}
          </div>
        </div>
      </div>
      <div class="lg:flex justify-between lg:mx-40 mx-5">
        <div>
          <div class="flex items-end my-6">
            <div class="w-16 h-12">
              <img :src="images.logoFooter" alt="logo" original />
            </div>
            <div class="ml-2 font-bold text-white text-2xl">Flash</div>
          </div>
          <div
            class="lg:w-434px font-medium text-lg leading-relaxed text-white"
          >
            {{ $t("but1a") }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="lg:mx-40 leading-6 space-y-4 text-white">
            <div class="font-bold text-xl leading-6 mb-8 mt-7">
              {{ $t("produitT") }}
            </div>
            <div class="font-medium text-lg">
              <a href="#flashx">FlashX</a>
            </div>
            <div class="font-medium text-lg">
              <a href="#flashpayment">FlashPayment</a>
            </div>
            <div class="font-medium text-lg"><a href="#faqs">FAQs</a></div>
            <div class="font-medium text-lg"><a href="#about">About</a></div>
          </div>
          <div class="leading-6 space-y-4 mr-10 text-white mt-8">
            <div class="font-bold text-xl mb-7">{{ $t("contactT") }}</div>
            <div class="font-medium text-lg cursor-pointer" @click="openForm">
              {{ $t("entrepriseP1") }}
            </div>
            <div
              class="font-medium text-lg cursor-pointer"
              @click="openTwitter"
            >
              Twitter
            </div>
            <div
              class="font-medium text-lg cursor-pointer"
              @click="openFacebook"
            >
              Facebook
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:flex justify-between lg:px-40 px-5 lg:mt-14 mt-16 border-t">
      <div
        class="lg:flex lg:space-x-4 font-semibold text-base leading-6 text-white py-5"
      >
        <div>
          {{
            $t("flash") + 2022 + " - " + new Date().getFullYear() + $t("right")
          }}
        </div>
        <div class="flex items-center">
          <div class="font-semibold my-4 mr-4 lg:my-0">
            <a href="#"> {{ $t("confidentialites") }}</a>
          </div>
          <div class="font-semibold">
            <a href="#"> {{ $t("conditions") }} </a>
          </div>
        </div>
      </div>
      <div
        class="font-medium text-base text-white leading-7 lg:py-5 lg:pb-0 pb-10"
      >
        <a href="#">{{ $t("powered") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
/*------------------IMAGES VOTRE COMMERCE AUX BOUTS DES DOIGTS------------------*/
/*Import Images*/
import logoFooter from "../../assets/images/img_logoFooter.png";

export default {
  name: "index",
  metaInfo: {
    title: "bitcoinflash",
    meta: [
      {
        name: "bitcoinflash",
        content:
          "Achat du bitcoin à partir de 100 fr CFA en utilisant le lightning network",
      },
    ],
  },
  data() {
    return {
      icons: {},
      images: {
        logoFooter,
      },
      isHome: true,
    };
  },
  created() {},
  methods: {
    openHome() {
      this.$router.push({ path: "/" });
    },
    openForm() {
      this.$emit("openFormHeader", true);
    },
    openTwitter() {
      window.open("https://twitter.com/_bitcoinflash", "_blank");
    },
    openFacebook() {
      window.open("https://www.facebook.com/bitcoinflashx", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables.scss";
a {
  color: unset;
}
</style>
