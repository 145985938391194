<template>
  <div v-click-outside="onClickOutside" class="radio w-full">
    <div class="relative">
      <selectListe
        v-if="activeOption && defilePosition === 'top' && !writeOption"
        :option="optionSend"
        :style="{ width: width }"
        class="defileTop"
        :displayKey="displayKey"
        :size="size"
        @info="retourSearch"
        :addOption="addOption"
        @adding="addNewOption"
      />

      <selectListe
        v-if="activeOption && defilePosition === 'top' && writeOption"
        :option="optionSend"
        :style="{ width: width }"
        :value="value"
        :size="size"
        :displayKey="displayKey"
        class="defileTop"
        @info="retourSearch"
        :addOption="addOption"
        @adding="addNewOption"
      />

      <div
        class="champ flex items-center pl-4 pr-2 cursor-pointer"
        :style="{
          'background-color': background,
          border: border,
          'min-height': minheight,
          height: height,
          'border-radius': radius,
          'font-size': size,
        }"
        @click="openOption"
      >
        <icon
          v-if="icon !== null"
          :data="icon"
          :height="iconHeight"
          :width="iconWidth"
          class="mr-4 cursor-pointer"
          original
        />
        <div v-if="!writeOption && !isMultiple" class="mr-2 w-full text-left">
          <span v-if="value && !displayKey">{{ value }}</span>
          <span v-if="value && displayKey">{{ value[displayKey] }}</span>
          <span class="text-gray-400 placeholder" v-if="!value">{{
            placeholder
          }}</span>
        </div>

        <div
          v-if="isMultiple"
          class="mr-2 w-full text-left flex flex-wrap pt-3 pb-1"
        >
          <div
            v-for="(item, index) in selectedItem"
            :key="index"
            class="multiItem p-1 pl-2 pr-2 mr-4 mb-2 flex items-center"
          >
            <div class="w-5/6" v-if="!displayKey">{{ item }}</div>
            <div class="w-5/6" v-if="displayKey">{{ item[displayKey] }}</div>
            <div
              class="ml-3 close w-5 h-5 rounded-full flex justify-center items-center bg-gray-100"
              @click="deleteItem(index)"
            >
              <div>x</div>
            </div>
          </div>
        </div>

        <div v-if="writeOption" class="mr-2 w-full">
          <input
            v-model="value"
            type="text"
            class="w-full h-full pl-2"
            :placeholder="placeholder"
          />
        </div>

        <div class="w-10 flex justify-end pr-1">
          <icon
            v-if="icons !== null"
            :data="icons.ic_down"
            height="13"
            width="13"
            class="cursor-pointer"
            original
          />
        </div>
      </div>

      <div class="absolute w-full" style="top: '0px'">
        <selectListe
          v-if="activeOption && defilePosition !== 'top' && !writeOption"
          :option="optionSend"
          :style="{ width: width }"
          :first="first"
          :size="size"
          :displayKey="displayKey"
          @info="retourSearch"
          class="border border-red-500"
          :addOption="addOption"
          @adding="addNewOption"
        />
        <selectListe
          v-if="activeOption && defilePosition !== 'top' && writeOption"
          :option="optionSend"
          :style="{ width: width }"
          :value="value"
          :first="first"
          :displayKey="displayKey"
          :size="size"
          @info="retourSearch"
          :addOption="addOption"
          @adding="addNewOption"
        />
      </div>
      {{ sendValue }}
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import selectListe from "./selectFiltre";
import ic_down from "../../../assets/icons/down.svg";

export default {
  name: "multi-select",
  components: {
    selectListe,
  },
  props: {
    defilePosition: {
      type: String,
      default: "bottom",
    },
    option: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      default: -2,
    },
    writeOption: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    radius: {
      type: String,
      default: "4px",
    },
    minheight: {
      type: String,
      default: "40px",
    },
    valueT: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Cliquez pour sélectionnez',
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "40px",
    },
    iconHeight: {
      type: String,
      default: "30px",
    },
    iconWidth: {
      type: String,
      default: "30px",
    },
    size: {
      type: String,
      default: "14px",
    },
    border: {
      type: String,
      default: "1px solid rgba(93, 56, 224, 0.3)",
    },
    first: {
      type: String,
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    background: {
      type: String,
      default: "#F4F5F7",
    },
    name: {
      type: String,
      default: "",
    },
    displayKey: {
      type: String,
      default: null,
    },
    addOption: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      value: null,
      activeOption: false,
      temoinValue: null,
      icons: {
        ic_down,
      },
      selectedItem: [],
      options: null,
      temoin: null,
      selected: null
    };
  },

  computed: {
    optionSend: function () {
      return this.option
    },
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if(this.displayKey && this.writeOption) {

        if(this.selected) {
          if (this.value !== this.temoin) {
            this.activeOption = true;
            if (this.name) {
              this.$emit("oga", this.selected, this.name);
            } else {

              this.$emit("info", this.selected);
            }
          }
        } else {
          if (this.value !== this.temoin) {
            this.activeOption = true;
          }
        }
      } else {
        if (this.value !== "Autres" && this.value !== null) {
          if (this.value !== this.temoin) {
            if (this.name) {
              this.$emit("oga", this.value, this.name);
            } else {
              this.$emit("info", this.value);
            }
          }
        }

        if (this.value !== this.temoinValue) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.activeOption = true;
        }
        if (this.isMultiple && this.selectedItem.length > 0) {
          if (this.name) {
            this.$emit("oga", this.selectedItem, this.name);
          } else {
            this.$emit("info", this.selectedItem);
          }
        }
      }

    },
  },

  created() {
    this.options = this.option;
    if (this.valueT) {
      this.value = this.valueT;
      this.temoinValue = this.value;
      this.selectedItem = this.valueT;
    }
  },

  methods: {
    addNewOption(a) {
      this.$emit('adding', a)
    },

    onClickOutside() {
      this.activeOption = false;
    },

    openOption() {
      this.activeOption = !this.activeOption;
    },

    deleteItem(index) {
      this.options.push(this.selectedItem[index]);
      this.selectedItem.splice(index, 1);
    },

    retourSearch(answer) {
      this.selected = answer;
      this.activeOption = false;
      this.position = -1;

      if (this.displayKey && this.writeOption){
        this.value = answer[this.displayKey];
        this.temoinValue = answer[this.displayKey];
      } else {
        this.value = answer;
        this.temoinValue = answer;
      }

      if (this.isMultiple) {
        this.selectedItem.push(answer);

        for (let item in this.options) {
          if (this.options[item] === answer) {
            this.options.splice(item, 1);
          }
        }
        this.value = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.champ {
  box-sizing: border-box;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  font-family: $font-default;
  color: #0e0e0e;
}
.placeholder{
  font-size: 11px;
}
.svg-fill {
  fill: #fff;
}
.close {
  color: red;
  font-weight: 500;
  font-size: 9px;
  background-color: #CDD1D6;
}
.champ:focus-within {
  border: 1px solid $focus-color;
}
.champ:hover {
  border: 1px solid $hover-color;
}


.multiItem {
  background: #ececec;
  border-radius: 4px;
  width: auto;
}
.multiItem:hover {
  background: #cfeffa;
  .close{
    background-color: #fdb6b6;
  }
}
.mult{
  width: 90%;
}
.defileTop {
  position: absolute;
  margin-top: -260px;
}
input {
  background-color: transparent;
  border-radius: 20px;
}

@media screen and (max-width: 700px) {
  .champ {
    height: 49.97px;
    font-size: 16px;
  }
}
</style>
